// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif !important;
  // text-transform: capitalize;
}
.text-wrap{
  overflow-wrap: break-word;
}
.cursor{
  cursor: pointer;
}

$theme-color: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
$theme-color2: linear-gradient(-135deg, #899fd4 0%, #a389d4 100%);

.reject-btn {
  border-radius: 20px;
  background: #ccf5c5;
  text-decoration: none;
  cursor: pointer;
  color: #333333;
  margin-left: 5px;
  font-weight: 400;
  font-size: 12px;
}

.accept-btn {
  border-radius: 100px;
  background: #ffe3e1;
  text-decoration: none;
  cursor: pointer;
  padding: 3px;

  color: #333333;
  margin-left: 5px;
  font-weight: 400;
  font-size: 12px;
}

.delete-btn {
  border-radius: 20px;
  background: #ffe3e1;
  text-decoration: none;
  cursor: pointer;
  color: #333333;
  margin-left: 5px;
  font-weight: 400;
  font-size: 12px;
}

// a:hover {
//   color: #fff;
// }
.navbar-toggler{
  outline: none !important;
}
.navbar-toggler:focus {
  outline: none !important;
}

.card-bt {
  margin-bottom: 23px;
}

.alert {
  color: #a389d4;
  font-size: xxx-large;
  position: absolute;
  top: 40px;
  right: 0px;
}

.theme-bg {
  background: $theme-color;
}

.d-flex {
  display: flex;
  flex-direction: row;
}

.box-card {
  padding-left: 30px;
}

.text-signal {
  font-size: 10px;
  color: #1de9b6;
  margin: 10px;
}

.Button-Add {
  display: flex;
  justify-content: end;
}

.Add {
  display: flex;
  justify-content: end;
}

.action-btn {
  justify-content: space-evenly;
  display: flex;
}

.login-img {
  // background: #5141e0;
  background-image: url('../assets/images/right_section.png');
  width: 25rem;
  background-size: cover;
}

.login-img1 {
  // background: #5141e0;
  background-image: url('../assets/images/st-landing/student-login.png');
  width: 25rem;
  background-size: cover;
}

.svg-img {
  background-image: url(../assets/images/pagenotfound.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.text-login {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.download {
  position: absolute;
  right: -22px;
  top: 40px;
}

.clm-btn {
  display: flex;
  flex-direction: column;
}

.server500-img {
  background-image: url(../assets/images/500.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.form-card {
  background: white;
  padding: 14px;
  border-radius: 5px;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.edit-subs-card {
  background: white;
  padding: 14px;
  padding: 50px;
  border-radius: 5px;
  margin: 0 auto;
}

.bg-drop {
  background-color: #fff;
}

@media (max-width: '980px') {
  .login-img {
    display: none;
  }
}

.breadcrumb-item a {
  text-decoration: none;
  color: black;
  font-weight: 400;
}

.breadcrumb-item a:hover {
  color: rgb(9, 141, 218);
}

.simplebar-content {
  background: #1670f8 !important;
}

.sidebar-nav {
  background: #1670f8 !important;
}

// .d-none {
//   background: #1670f8;
// }

//////Login
///
///
///
.best-learning h3 {
  font-size: 60px;

  @media (max-width: '1440px') {
    font-size: 40px;
  }
}

.best-learning {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  align-items: start;
  flex-direction: column;
  color: rgb(255, 255, 255);
}

.login-right h1 {
  font-size: 44px;
  color: #333333;

  @media (max-width: '1440px') {
    font-size: 30px;
  }
}

.login-right .learning-size-wrap {
  color: #606176;
}

.form-control:focus {
  // background-color: ;
  border-color: #2495e6;
  outline: 0;
  box-shadow: none !important;
}

.form-check-input {
  background-color: none !important;
}

.form-check-input:checked {
  background-color: #2495e6 !important;
}

.switch-custom .form-check-input:checked {
  background-color: #2dd36f !important;
  border-color: #2dd36f !important;
}

.switch-manage-color .form-check-input:checked {
  background-color: red !important;
  border-color: red !important;
}

.form-check-input:focus {
  color: #2495e6;
  background-color: none !important;
  border-color: #2495e6;
  outline: 0;
  box-shadow: none !important;
}

.labe-color {
  color: #606176;
}

.email-input {
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.link-color {
  color: #1670f8;
}

.link-color-student {
  background: #253f70 !important;
}

.link-color-student:hover {
  background: #324e81 !important;
}

.link-color:hover {
  color: #1670f8;
}

// .btn :hover {
//   color:;
//   background-color:;
//   border-color: ;
// }
.login-button {
  font-weight: 600;
  font-size: 16px;
  color: #1670f8;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: transparent !important;
  color: #0d6efd !important;
  border-color: transparent !important;
}

.login-button :active {
  background: transparent !important;
}

.border-bottom-sign {
  border-bottom: 3px solid #1670f8;
  padding-bottom: 21px;

  text-align: center;
  color: #1670f8;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.login-teacher {
  border: 1px solid #1670f8;
  border-radius: 100px;
}

.login-student {
  border: 1px solid #eeeeee;
  border-radius: 100px;
}

.login-student-btn {
  font-weight: 400;
  font-size: 16px;
  color: #606176;
}

.login-main {
  background: #1670f8;
  border-radius: 100px;
  border-color: #fff;
}

.tabs-info {
  --cui-nav-tabs-border-width: none !important;
}

.tabs-info .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-weight: 600;
  font-size: 16px;
  color: #1670f8 !important;
  border-bottom: 3px solid #1671f8 !important;
}

.tabs-info .nav-link {
  color: #606176 !important;
  border-bottom: 1px solid #efefef !important;
}

.tabs-info li {
  margin-right: 40px;
}

// .form-select {

//   background-image: url(image.png)

// }
.form-content-expert {
  .tabs-inner-content {
    .form-select {
      border: 1px solid #b1b7c1 !important;
    }
    .email-input {
      border: 1px solid #b1b7c1 !important;
    }
  }
  .border-high {
    border: 1px solid #b1b7c1 !important;
  }
}
.text-area-custom {
  height: 155px;
}
.tabs-inner-content .form-select {
  background-image: url('../assets/images/dropdown.svg');
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-size: 4%;
}
.social-links-wrap {
  margin-top: -230px;
  z-index: 99;
}
.tabs-inner-content .subject-select .img {
  background-image: url('../assets/images/dropdown.svg');
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-size: 3%;
}
.browse-btn-upload {
  background: #b1b7c1 !important;
  border-radius: 0px 5px 5px 0px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.input-group-custom-wrap {
  font-size: 16px;

  border-radius: 5px 0px 0px 5px !important;
}
.tabs-inner-content .form-select:focus {
  border-color: var(--cui-form-select-focus-border-color, #1671f8);
  outline: 0;
  box-shadow: none !important;
}

.tabs-inner-content label {
  color: #606176;
}

.input_file_type[type='image']:not(:disabled):not([readonly]) {
  opacity: 0;
}

.upload-sec {
  color: #606176;
  font-size: 13px;
}

.expert-form-btn {
  background: #3bb77e !important;
}

.sidebar {
  --cui-sidebar-bg: #fff !important;
}

.main-menu {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

// .sidebar-nav .nav-link.active {
//   background: #0052CD !important;

// }

.sidebar-nav .nav-link:hover {
  background: #0052cd !important;
}

.sidebar-nav .nav-link {
  padding-left: 17px !important;
}

.header-border {
  --cui-header-divider-border-color: none !important;
}

.price-card {
  font-size: 37px;
  letter-spacing: -0.02em;
  color: #1c3669;
  font-weight: 700;

  @media screen and (max-width: 1400px) {
    font-size: 25px;
  }
}

.green-num {
  color: #30d988;
}

.month-card {
  font-weight: 400;
  font-size: 10px;
  color: rgba(28, 31, 55, 0.58);
}

.wallet-spacing {
  font-weight: 500;
  font-size: 16px;
  color: #a3aed0;
}

.assignment-cards .Title {
  font-weight: 500;
  font-size: 20px;
  color: #333333;
}

.assignment-cards h6 {
  font-weight: 400;
  font-size: 16px;
  color: #606176;
}

.color-wrap {
  background: #1c3669 !important;
}

//back
.back-btn {
  width: 30px;
  height: 30px;
  background: #fff;
  color: #1670f8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}

//proof
.proof_tab a:hover {
  color: none !important;
}

.wrapper {
  background: rgb(245, 245, 245);
}

.meeting-request {
  font-weight: 500;
  font-size: 20px;
  color: #333333;
}

.card-personal-info {
  padding: 50px;
}

.btn-8 {
  background-color: #f0ecfc;
  background-image: linear-gradient(315deg, #f0ecfc 0%, #c797eb 74%);
  line-height: 42px;
  padding: 0;
  border: none;
}

.btn-8 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.btn-8:before,
.btn-8:after {
  position: absolute;
  content: '';
  right: 0;
  bottom: 0;
  background: #c797eb;
  /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
  transition: all 0.3s ease;
}

.manage-ptm {
  background: #1670f8;
  border-radius: 20px;
  border: 1px solid #1670f8;
}

.manage-ptm {
  color: #fff;
  border-radius: 100px;
  padding: 10px 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  background: #1670f8;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;

  outline: none;
  border: none;
  transition: all 0.3s ease;
}

.upload-pic {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: 'Poppins', sans-serif;
  line-height: 1;
  border-radius: 50%;

  user-select: none;
  color: rgb(245, 245, 245);
  /* background-color: rgb(189, 189, 189); */
  font-size: 16px;
  text-transform: uppercase;
  width: 150px;
  height: 150px;
  background: #f8f8f8;
}

.button-custom:hover {
  background-color: #4070f4;
}

//custom
.button-custom {
  color: #fff;
  border-radius: 100px;
  padding: 10px 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  background: #1670f8;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border: none;
  transition: all 0.3s ease;
  user-select: none;
}

.add-btn {
  color: #fff;
  border-radius: 100px;
  padding: 4px 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  background: #1670f8;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border: none;
  transition: all 0.3s ease;
}

.download-btn {
  color: #fff;
  border-radius: 100px;
  padding: 4px 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  background: #1de9b6;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border: none;
  transition: all 0.3s ease;
}

.chat-sender-color {
  background: #f5f5f5;
  border-radius: 0px 20px 20px 20px;
  padding: 10px;
  margin-bottom: 10px;
}

.chat-receiver-color {
  background: #e8fffe;
  border-radius: 0px 20px 20px 20px;
  padding: 10px;
  margin-bottom: 10px;
}

.manage-custom {
  color: #fff;
  border-radius: 100px;
  padding: 10px 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  background: #1670f8;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;

  outline: none;
  border: none;
  transition: all 0.3s ease;
}

// .btn:focus-visible {
//   color: $blue;
//   background-color: $blue;
//   border-color: none !important;
//   outline: 0;
//   box-shadow: none !important;
// }

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: #fff;
  background-color: $blue;
  border-color: none !important;
}

.profile-buttons {
  text-align: right;
}

.cancel-button {
  background: #fff;
  color: #333333;
  border: 1px solid #1670f8;
}

.cancel-button:hover {
  background-color: #1670f8;
  color: #fff;
  border: 1px solid transparent;
}

.close-btn:hover {
  border: 2px solid gray;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.sidebarstyle {
  margin-left: 5px;
  border-radius: 10px 0 0 10px !important;
  margin-bottom: 2px;
  margin-top: 5px;
}

.sidebarstyle .svg {
  color: #fff;
}

.img-size {
  max-width: 74px;
}

// Input
input[type='file'] {
  color: transparent;
}

.search {
  width: 180%;
}

.force-overflow {
  min-height: 450px;
}

#wrapper {
  text-align: center;
  margin: auto;
}

.sidebar-nav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.sidebar-nav::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background-color: #7fadf4;
  border: 0.5px solid #8165a5;
}

.toggle-custom {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

.modal-outer .modal-content {
  background: #fafafa;
  border-radius: 20px;
  padding: 30px 20px;
  transition: all 0.3s ease;
}

.button-subscription {
  margin-left: auto;

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    margin-top: 20px;
  }
}

.sidebar-nav .nav-link.active {
  background: #0052cd;
}

.pointer {
  cursor: pointer;
}

.modal-btn {
  display: flex;
  justify-content: center;
}

.closebar {
  width: 80px;
}

.open {
  width: 100%;
}

._sidebar-nav.scss {
  background: transparent !important;
}

.design-upload {
  border-radius: 50%;
  background: #f8f8f8;
  width: 119px;
  height: 119px;
}

.nav-group-items {
  background-color: #1670f8 !important;
}

.request-btn {
  background-color: #1670f8;
  border: 1px solid #1670f8;
  border-radius: 14px;
}

.payment-btn {
  background-color: #1670f8;
  border: 1px solid #1670f8;
  border-radius: 50px;
  width: 130px;
  margin-right: 15px;
}

.payment-btn:hover {
  background-color: none !important;
}

.iSAVrt {
  background-color: #eee !important;
}

.get-exp-form {
  padding: 20px;
  width: 100%;

  padding-bottom: 8px;
  color: #fff;
  border-radius: 5px 5px 0px 0px;

  .modal-inner-heading {
    font-weight: 500;
    font-size: 32px;
    color: #373737;
  }
}

.tabs-inner-wrap .form-select-expert {
  background-size: 2% !important;
}

.tabs-wrapper-content .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  width: 247px;
}

.footer-site .link-pages:hover {
  color: #fff !important;
}

.tabs-inner-wrap .form-select {
  background-image: url('../assets/images/form-to.svg');
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.button-edit {
  font-size: 11px;
  font-weight: 600;
  display: inline-block;
  color: #000;
  border-radius: 50px;
  cursor: pointer;
}
.dis{
  font-size: 10px;
}

.article-size-blog {
  width: 100%;
  height: 244px;
}

.condensed {
  transition: ease-in-out 0.2s;
  line-height: 24px;
  padding: 0 8px;

  @media screen and (max-width: 786px) {
    padding: 0 0px;
  }

  @media screen and (max-width: 1399px) {
    padding: 0 4px;
  }
}

.edit-bg {
  background: #ccf5c5;
}

.delete-bg {
  background: #ffe3e1;
}

.form-select:focus {
  border-color: #1670f8;
  outline: 0;
  box-shadow: none !important;
}

.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}

.card-main-signin {
  width: 100%;
  height: 100vh;
}

.card-inner-width {
  // width: 60%;
  @media screen and (min-width: 1499px) {
    width: 80%;
  }

  @media screen and (min-width: 1600px) {
    width: 60%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .spacing-font {
    padding-top: 5px !important;
    padding-bottom: 5px;
  }
}

.name-size {
  font-size: 14px;
}

.eye-icon-custom {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.eye-icon-custom:hover {
  transform: scale(1.1);
  cursor: pointer;
  color: #1670f8;
}

.zIndex {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  align-items: center;
  justify-content: center;
}

.zIndex1 {
  left: 0;
  right: 20%;
}

// .blur-scroll-wrap{
//   opacity: 0.5;

// }

.date-pick-icn {
  right: 0px;
  top: 8px;
  left: 12px;
  overflow: hidden;
  width: 20px;
}

input[type='datetime-local']::before {
  content: 'Deadline:';
}

.order-us-wrapper .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  border-radius: 100px;
  padding: 10px 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  background: #1670f8;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border: none;
  transition: all 0.3s ease;
}

.labelbtn-wrap {
  padding: 4px 5px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  min-width: 103px !important;
  text-align: center;
}

.resubmit-label {
  color: #1da7ff;
  border: 2px solid #1da7ff;
  background-color: #f0f9ff;
  line-height: 1;
  margin-bottom: 0px;
  border-radius: 50px;
  display: block;
}

.pending-label {
  color: #ffc409;
  border: 2px solid #ffc409;
  background-color: #fff3ce;
  line-height: 1;
  border-radius: 50px;
  padding: 4px 13px;
  margin-bottom: 0px;
  display: block;
}


.subscribed{
  color: #2dd36f;
  line-height: 1;
  margin-bottom: 0px;
  display: block
}
.footer-site{
  z-index: 1;
  position: relative;
}
.approve-label {
  color: #2dd36f;
  border: 2px solid #2dd36f;
  background-color: #e7fff0;
  line-height: 1;
  border-radius: 50px;
  display: block;
}

.rejected-label {
  color: #eb445a;
  border: 2px solid #eb445a;
  background-color: #fbdade;
  line-height: 1;
  border-radius: 50px;
  padding: 4px 9px;
  margin-bottom: 0px;
}

.submitted-label {
  color: rgb(29, 167, 255);
  border: 2px solid rgb(29, 167, 255);
  background-color: rgb(240, 249, 255);
  line-height: 1;
  border-radius: 50px;
  margin-bottom: 0px;
}

.overflow-hide {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.star-icn {
  color: #ff0000;
}

.size-icn-wrap {
  width: 18px;
}

.data-custom .rdt_TableCell div {
  white-space: normal !important;
}

.data-custom-wrap .rdt_TableCell div {
  overflow: hidden !important;
}

.edit-size-img {
  width: 18px;
  cursor: pointer;
}

.sidebar-nav .nav-link:hover {
  background: #0052cd !important;
  border-radius: 10px 0px 0px 10px;
}

.sidebar-nav .nav-group.show {
  background: var(--cui-sidebar-nav-group-bg);
  border-radius: 10px 0px 0px 10px !important;
  margin-left: 10px;
}

.del-size-img {
  width: 20px;
  cursor: pointer;
  height: 20px;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}

.share {
  background: #fafafa;
  height: 41px;
  width: 115px;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);
  border: none;
  font-size: 25px;
}

/* .share:hover{
  transform: scale(1.1);
} */
.share span {
  position: absolute;
  background: #1670f8;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  transition: 0.3s linear;
  z-index: 999;
  border-radius: 40px;
  user-select: none;
}

.share span .fa-share-alt {
  margin-right: 5px;
  font-size: 16px;
}

.share:hover span {
  transform: translateX(-100%);
}

.share a {
  margin: 5px;
}

.share:hover {
  font-size: 25px;
}

.data-custom-wrap .rdt_Table {
  table-layout: fixed;
  overflow: auto;
  min-width: 1300px;
}

.download-wrap-save {
  position: absolute;
  right: -3px;
  top: 43px;
}

.upload-icon-wrap-custom {
  position: absolute;
  border-radius: 50%;
  border: navajowhite;
  width: 24px;
  height: 24px;
  bottom: 0;
  background: red;
  right: 0;
  z-index: 1;
}

.up-pic-mandatory {
  font-weight: 500;
  font-size: 16px;
  color: #606176;
}

.up-pic-mandatory span {
  font-size: 14px;
  font-weight: 400;
}

.search-icon-wrap {

  z-index: 6;
  width: 20px;
}

.form-search-input {
  padding-left: 33px;
}

.sidebar-contact {
  background: #1c3669;
}

.ques-wrap-outer {
  position: absolute;
  top: 0;
  margin-top: -20px;
}

.contact-dash-btn {
  border-radius: 6px;
  color: #1670f8;
  background: #ffffff;
}

.sidebar-wrap-custom {
  height: 100vh;
}

// hide number type arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.wallet-wraper {
  background: #f5f5f5;
}

.wallet-wraper h5 {
  background: #f5f5f5;
  font-weight: 600;
  font-size: 20px;
  color: #606176;
}

.wallet-wraper h5 span {
  color: #1670f8;
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 200px !important;
}

.ck-editor__editable:not(.ck-editor__nested-editable) {
  min-height: 200px;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}

.book-edit-cover {
  position: absolute;
  right: 39px !important;
  z-index: 1;
  top: -10px !important;
}

.border-dash {
  border: 1px dashed #b1b7c1;
  border-radius: 5px;
}

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1670f8;
  border-color: #1670f8;
}

.page-link:focus {
  z-index: 3;
  color: var(--cui-pagination-focus-color);
  background-color: var(--cui-pagination-focus-bg);
  outline: 0;
  box-shadow: none !important;
}

.pagination {
  --cui-pagination-border-width: 1px #1670f8;
}

.drop-size {
  width: 30px !important;
}

.pagination {
  border: none !important;
}

.offcanvas {
  background: #1670f8;
}

.highlighted-text-wrap {
  color: orange;
  margin-left: 12px;
  font-size: 15px;
  font-weight: 400;
}

.teacher-color {
  color: orange;
}

// .toggle-custom-color {
//   background: #1670f8;
// }



.offcanvas {
  background: #1670f8;
}

.off-canvas-style {
  overflow-y: clip;
}

.simplebar-scrollbar:before {
  background: #ffff !important;
}

.disabled-bg:disabled {
  background: #eeeeee !important;
  color: #000 !important;
}

.facebook_icon {
  height: 36px;
  width: 36px;
  border: none;
  background-image: url(../assets/images/facebook.svg);
  border-radius: 50% !important;
}

.blk-element-st {
  display: inline-block;
  min-width: 72px !important;
}

.sidebar-nav svg.nav-icon {
  color: #fff;
}

.outer-bg-color-st {
  padding: 20px;
  background: #f5f5f5;
}

.btn-close-modal {
  top: 9px;
  border: 1px solid #606176;
  padding: 6px;
  right: 8px;
  border-radius: 100px;
}

// checkout
body {
  background-color: #eeeeee;
}

.green {
  color: rgb(15, 207, 143);
  font-weight: 680;
}

@media (max-width: 567px) {
  .mobile {
    padding-top: 40px;
  }
}

.cart-payment {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.phone-register-st .form-control:disabled {
  background-color: #fff !important;
  border: 1px solid #eeeeee !important;
  border-radius: 4px;
  opacity: 1;
}

.responsiveNavLink {
  background-color: #0052cd;
  border-radius: 10px !important;
}

.dropLinkstyle {
  background-color: #0052cd;
}

.searchcard {
  width: 50%;
  margin: 0 auto;
  margin-top: 50px;
}

.searchfont {
  text-align: center;
  font-size: 25px;
}

.cardborder {
  border: 1px solid transparent;
}

.Readmore {
  cursor: pointer;
}

#zmmtg-root {
  display: none;
}

.img-wrap-blog {
  width: 80px;
  height: 80px;
  min-width: 80px;
}

.imge-blog-article {
  object-fit: cover;
  width: 80px;
  height: 80px;
}

.main-card-blog {
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: all 0.3s ease;
  cursor: pointer;
}

.article-news-resize {
  width: 100%;
  height: 160px;
}

.main-card-blog:hover {
  transform: translate(0, -10px);
}

.main-card-blog a {
  color: #1670f8;
  font-weight: 500;
}

.gold {
  color: #3bb77e;
}

.silver {
  color: #edaf36;
}

.platinum {
  color: #2375f3;
}

.diamond11 {
  color: #1c3669 !important;
}

.student-card-custom {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 40px;
  border-radius: 7px;
}

.login-btn-wrap-main {
  display: flex;
  flex-wrap: wrap;
}

.avta-upload-main-outer {
  display: flex;
}

//

.bg-custom-job {
  background: #fff;
}

.card-inner-jobs {
  padding: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 280px;
}

.nowrap-custom {
  white-space: nowrap;
}

.custom-answering-btn {
  background: #1670f8 !important;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
}

.jobs-tabs-admin .nav-item .nav-link.active {
  background-color: #1670f8 !important;
}

.assignment-wrap-new .rdt_TableCell:nth-child(9) {
  min-width: 140px;
}

.assignment-wrap-new .rdt_TableCell:nth-child(9) span {
  display: inline-block;
}

@media (max-width: '980px') {
  .login-img1 {
    display: none;
  }

  .login-button {
    font-size: 12px;
  }

  .login-btn-wrap-main {
    display: block;
  }

  .login-teacher {
    margin-bottom: 20px;
  }

  .avta-upload-main-outer {
    display: block;
  }
}

.rdt_TableCell .keywordstyle {
  width: 1000px !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.marginTop {
  margin-top: -1px;
}

.asignmentstyle {
  .eVPPvF {
    min-width: 170px !important;
  }
}

.chat-sender-color {
  display: inline-flex;
  justify-content: end;
}

.chat-receiver-color {
  display: inline-flex;
}

.textfieldchat {
  border-radius: 30px;
  background: #eeeeee;

  box-shadow: none;
  width: 90%;
  padding: 10px 30px;
  border: none;
  outline: none;
  margin-right: 20px;
}

.fieldbg-color {
  background-color: #fff;
}

.react-time-picker__wrapper {
  border: none !important;
}

.ans-outer-wrap-teach .ans-box-wrapper {
  background: #ffff;
  border-radius: 4px;
}

.ans-outer-wrap-teach .ans-box-wrapper h2 {
  font-weight: 400;
  font-size: 44px;
  color: #1670f8;
}

.copyright-table-form {
  display: flex;
  width: calc(100% - 160px);
}

.label-copyright {
  min-width: 250px;
}

.entries {
  font-size: 15px;
}

.copy-right-wrap {
  display: flex;
}

.meeting-height-wrap-st {
  height: calc(100vh - 252px);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.space-pegination-wrap {
  white-space: nowrap;
}

.placeholder-custom input::placeholder {
  font-weight: 300;
  font-size: 14px;
  color: #606176;
}

.badge-notify {
  bottom: 12px;
  right: -6px;
  font-size: 10px;
}

.spacing-custom-height {
  min-height: 70px;
}

.color-wrap-custom-bg {
  background: #f9f9f9;
  border-radius: 5px;
}

.lh-wrap-custom {
  line-height: 2px;
  display: block;
}

.border-color-wrap-dash {
  border: 1px solid #f9f9f9;
}

.chat-section {
  overflow-y: auto;
  height: calc(100vh - 402px);
  padding-right: 20px;
  padding-bottom: 100px;
  
}

.text-input-section {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 10px;
}

.landing-page-bg {
  // background: #eef5ff;
  background: #fffefcb3;
}

/* width */
.chat-section::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.chat-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f9f9f9;
  border-radius: 10px;
}

/* Handle */
.chat-section::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
.chat-section::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.user-list-wrap ::-webkit-scrollbar {
  width: 4px;
}

.user-list-wrap ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f9f9f9;
  border-radius: 10px;
}

.user-list-wrap ::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.user-list-wrap {
  padding-left: 10px;
  position: relative;
  height: calc(100vh - 280px);
}

.user-list {
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
  padding-bottom: 20px;
}

.pos-find-btn {
  position: fixed;
  bottom: 20px;
}

.user-wrap-list-teacher {
  height: calc(100vh - 185px) !important;
}

.chat-sec-teacher {
  height: calc(100vh - 303px);
}

.image-text-bg {
  top: 42%;
  left: 10%;
  right: 10%;
  margin: 0 auto;
  display: block;
  text-align: center;
  color: #606176;
  font-weight: 600;
  line-height: 25px;
  font-size: 14px;
}

.read-more {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-color-shadow {
  // background: #ffffff;
  background:#e0e0e070;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}
.non-active-it {
  background: #ffffff;
  border: 1px solid #b1b7c1;
  border-radius: 5px;
  width: 111px;
  text-align: center;
  a {
    font-weight: 400;
    font-size: 14px;
    color: #75767e;
    text-decoration: none;
  }
}
.font-weight-bold {
  font-weight: 600;
}
.it-coding-active {
  background: #f1f1f1;
  border: 1px solid #b1b7c1;
  border-radius: 5px;
  position: relative;
  width: 111px;
  text-align: center;
  img {
    position: absolute;
    top: -10px;
    right: -5px;
    bottom: 0;
    z-index: 1;
  }
  a {
    font-weight: 400;
    font-size: 14px;
    color: #75767e;
    text-decoration: none;
  }
}

.content-btn-wrap {
  bottom: -31px;
}

.card-custom-position {
  bottom: 20px;
}

.news-wrap-outer-custom .card-custom-position {
  bottom: 0;
}

.text-JoinLink {
  color: #1670f8 !important;
}

.height-max-content {
  height: calc(100vh - 251px);
}

.seller-login-link {
  font-weight: 500;
  color: #1670f8;
  text-decoration: underline;
}

.main-navbar-wrap {
  display: flex;
}

.service-btn {
  margin-top: 8px;
  font-weight: 600;
  font-size: 15px;
  color: #252525;
}

.text-orange {
  color: orange;
  text-shadow: rgba(235, 174, 18, 0.56) 0px 22px 70px 4px;
}

.order-custom-position {
  position: absolute;
}

.width-custom-wraper {
  width: 50%;
}

.duplicate-btn-wrap {
  background: red;
  color: #ffff;
  font-size: 10px;
  border-radius: 6px;
  padding: 0px 8px;
}

.service-navbar-link {
  font-size: 16px;
}

.terms-use {
  text-align: left;
  font-size: 14px;
}

@media screen and (max-width: 1400px) {
  .height-max-content {
    height: auto;
  }

  .service-navbar-link {
    font-size: 14px;
  }
}

@media screen and (max-width: 999px) {
  .main-navbar-wrap {
    display: block;
  }

  .text-aligned-wrap {
    text-align: center;
  }

  .order-custom-position {
    position: static;
  }

  .bottom-margin-wrap {
    margin-bottom: 20px;
  }

  .login-teacher {
    text-align: center;
  }

  .login-student {
    text-align: center;
  }

  .space-pegination-wrap {
    white-space: normal;
  }

  .text-custom-center {
    text-align: center;
  }

  .width-custom-wraper {
    width: 100%;
  }

  .service-navbar-link {
    font-size: 14px;
    margin-top: 0px !important;
  }

  .copy-right-wrap {
    display: block;
  }

  .copyright-table-form {
    width: auto;
  }

  .navlink-tabs-teacher {
    width: 50%;
  }
}

.show-duplicate:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.fileUpload {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 10px;
  background-color: #f1f1f1;
  vertical-align: middle;
  line-height: 1;
  margin-left: auto;
  /* left: 100%; */
  border-radius: 7px;
}
@media screen and (max-width: 1200px) {
  .text-custom-center {
    text-align: center;
  }

  .login-btn-wrap-main {
    display: block;
    text-align: center;
  }

  .client-img-d-none {
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  .client-img-d-none {
    display: none;
  }
}
.hoverText:hover{
color: #0052cd;
}

.currency-icon{
  color: #3399ff;
  background: #f4f7fe;
  height: 75px;
  width: 75px;
  padding: 10px 25px;
}
.google-login-wrapper .inner-google-wrap .S9gUrf-YoZ4jf {
display: flex;
justify-content: center;
}

// .google-login-wrapper #gsi_381116_777786  .haAclf{
// display: none;
// }

.orderListing{
  padding: 50px;
}
.stuprofile{
  padding: 50px;
}
.stuReward{
  padding: 50px;
}
.stuSchdule{
  padding: 50px;
}
@media screen and (max-width: 556px) {
  .student-card-custom {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px;
    border-radius: 7px;
  }
  .stuprofile{
    padding: 15px;
  }
  .orderListing{
    padding: 15px;
  }
  .stuReward{
    padding: 15px;
  }
  .edit-subs-card {
    padding: 15px;
}
.stuSchdule{
  padding: 15px;
}
}